import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AuthGuardService } from './services/auth-gaurd-service';
import { DirectApprovalComponent } from './direct-approval/direct-approval.component';

export const routes: Routes = [
    {
        path: '', component: AppMainComponent, canActivate: [AuthGuardService],
        children: [
            {
                path: 'admin',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
            },
            {
                path: 'configuration',
                loadChildren: () => import('./configuration/configuration.module').then(mod => mod.ConfigurationModule)
            },
            {
                path: 'accounts',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./accounts/accounts.module').then(mod => mod.AccountsModule)
            },
            {
                path: 'profile',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./profile/profile.module').then(mod => mod.ProfileModule)
            },
            {
                path: 'products',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./products/products.module').then(mod => mod.ProductsModule)
            },
            {
                path: 'requests',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./requests/requests.module').then(mod => mod.RequestsModule)
            },
            {
                path: 'approval',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./approval/approval.module').then(mod => mod.ApprovalModule)
            },
            {
                path: 'billing',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./billing/billing.module').then(mod => mod.BillingModule)
            }
        ]
    },
    { path: 'directapproval', component: DirectApprovalComponent },
    { path: 'error', component: AppErrorComponent },
    { path: 'accessdenied', component: AppAccessdeniedComponent },
    { path: '404', component: AppNotfoundComponent },
    { path: 'login', component: AppLoginComponent },
   // { path: '**', redirectTo: '/directapproval' }

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled'
});
