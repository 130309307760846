import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ContextService } from './context.service';

const fileHeader = {
  headers: new HttpHeaders({
    'Authorization': 'Basic ' + btoa(environment.bucketUsernamePassword)
  })
};

const formHeader = {  // additional form header
  headers: new HttpHeaders({
    'Authorization': 'Basic ' + btoa(environment.bucketUsernamePassword),
    'Content-Type': 'application/json; charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient, private messageService: MessageService,
    public router: Router, public contextService: ContextService) {

  }

  headerWithToken() {
    const header: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('access-tocken')
      }),
      observe: 'response'
    };
    return header;
  }
  headerWithBasic() {
    const header: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Accept': 'application/json',
        'Authorization': 'Basic ' + sessionStorage.getItem('userAuth')
      }),
      observe: 'response'
    };
    return header;
  }
  headerWithoutAuth() {
    const header: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Accept': 'application/json'
      })
    };
    return header;
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {

    return this.http.get(`${environment.api_url}${path}`, this.headerWithToken())
      .pipe(catchError(this.handleError<any>(path)));
  }
  put(path: string, body): Observable<any> {

    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body), this.headerWithToken()
    ).pipe(catchError(this.handleError<any>(path)));
  }
  post(path: string, body: Object = {}): Observable<any> {

    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body), this.headerWithToken()
    ).pipe(catchError(this.handleError<any>(path)));
  }


  delete(path): Observable<any> {

    return this.http.delete(
      `${environment.api_url}${path}`, this.headerWithToken()
    ).pipe(catchError(this.handleError<any>(path)));
  }

  getT(path: string): Observable<any> {
    return this.http.get(
      `${environment.api_url}${path}`, this.headerWithBasic(),
    ).pipe(catchError(this.handleError<any>(path)));
  }
  postT(path: string, body: Object = {}): Observable<any> {

    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body), this.headerWithoutAuth()
    ).pipe(catchError(this.handleError<any>(path)));
  }


  // file upload

  postFile(path: string, fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.http.post(
      `${environment.fileServer_Api_Url}${path}`, formData, fileHeader
    ).pipe(catchError(this.handleError<any>(path)));
  }

  postTableRow(path: string, body: Object = {}): Observable<any> {

    return this.http.post(
      `${environment.fileServer_Api_Url}${path}`,
      JSON.stringify(body), formHeader
    ).pipe(catchError(this.handleError<any>(path)));
  }

  promiseGet(path, params: HttpParams = new HttpParams()) {

    const promise = this.http.get(`${environment.api_url}${path}`, this.headerWithToken()).toPromise();
    return promise;

    // promise.then((data) => {
    //   console.log('Promise resolved with: ' + JSON.stringify(data));
    // }, (error) => {
    //   console.log('Promise rejected with ' + JSON.stringify(error));
    // });
  }

  public handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      this.messageService.clear();

      if (error.status === 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Technical difficulties... Please contact admin.' });
        if (error.statusText === 'Unknown Error') {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
      if (error.status === 400) {
        if (error.error.message === 'Access Token Expired' || error.error.message === 'Login Error, Please Try again') {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        this.contextService.errorMessageFromApiService = error.error.message;
      }
      if (error.status === 401) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        if (localStorage.getItem('currentUser')) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
      if (error.status === 404) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        this.contextService.errorMessageFromApiService = error.error.message;
      }
      if (error.status === 500) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Technical difficulties... Please contact admin.' });
        this.contextService.errorMessageFromApiService = 'Technical difficulties... Please contact admin.';
      }
      // TODO: send the error to remote logging infrastructure
      console.error('error:', error);
      // TODO: better job of transforming error for user consumption

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.


      return of(result as T);
    };
  }
}
