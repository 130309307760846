import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// Components
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GrowlModule } from 'primeng/growl';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeTableModule } from 'primeng/treetable';
import { PanelModule } from 'primeng/panel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TooltipModule } from 'primeng/tooltip';
import { OrderListModule } from 'primeng/orderlist';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {EditorModule} from 'primeng/editor';
import {RatingModule} from 'primeng/rating';

// Services
import { ApiService } from './api.service';
import { ContextService } from '../shared/context.service';
import { LoggingService } from './logging.service';
// Directives
import { NumberDirective } from './numbers-only.directive';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { DateWithFormatPipe } from './datepipe';
import { CalendarModule } from 'primeng/primeng';


@NgModule({
  declarations: [
    NumberDirective,
    DateWithFormatPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgxTrimDirectiveModule
  ],
  exports: [
    NumberDirective,
    DateWithFormatPipe,
    NgxTrimDirectiveModule,
    ScrollPanelModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    GrowlModule,
    ToastModule,
    ConfirmDialogModule,
    TreeTableModule,
    PanelModule,
    InputSwitchModule,
    ListboxModule,
    PickListModule,
    TableModule,
    DropdownModule,
    FileUploadModule,
    RadioButtonModule,
    TreeModule,
    ContextMenuModule,
    TooltipModule,
    OrderListModule,
    MultiSelectModule,
    AutoCompleteModule,
    TabViewModule,
    OverlayPanelModule,
    CheckboxModule,
    DialogModule,
    AccordionModule,
    FieldsetModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    CalendarModule,
    EditorModule,
    RatingModule
  ]

})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ApiService, ContextService, LoggingService]
    };
  }
}
