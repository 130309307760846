import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/shared/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PendingService {
  officeEditData: any;
  constructor(private apiService: ApiService, private http: HttpClient) { }
  // getProductRequestList(): Observable<any> {
  //   return this.http.get('assets/productRequestData.json').pipe(
  //    map(res => res ? res.body : res )
  //   );
  // }
  getAllTask(roleId): Observable<any> {
    return this.apiService.get('/request/tasks/' + roleId).pipe(
      map(res => res ? res.body : res)
    );
  }
  updateTask(item, userRole, taskIndex): Observable<any> {
    return this.apiService.post('/request/tasks/' + userRole, item).pipe(
      map(res => res ? res.body : res)
    );
  }
  // getBillingFrequency(): Observable<any> {
  //   return this.apiService.get('/period').pipe(
  //    map(res => res ? res.body : res )
  //   );
  // }
  getBillingFrequency(): Observable<any> {
    return this.apiService.get('/billingfrequency').pipe(
      map(res => res ? res.body : res)
    );
  }
  getExistingOfficeById(officeId): Observable<any> {
    return this.apiService.get('/office/existingoffice/' + officeId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAccountById(officeId): Observable<any> {
    return this.apiService.get('/account/' + officeId).pipe(
      map(res => res ? res.body : res)
    );
  }

  getItemById(reqId, itemId): Observable<any> {
    return this.apiService.get('/productrequest/' + reqId + '/' + itemId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getProductById(id: number): Observable<any> {
    return this.apiService.get('/product/details/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getPaymentmode(): Observable<any> {
    return this.apiService.get('/category/paymentmode').pipe(
      map(res => res ? res.body : res)
    );
  }
  getUserList(): Observable<any> {
    return this.apiService.get('/workflow/userlist').pipe(
      map(res => res ? res.body : res)
    );
  }
  updateItemStatus(item, nodeId, userId): Observable<any> {
    return this.apiService.put('/productrequest/item/' + nodeId + '/' + userId, item).pipe(
      map(res => res ? res.body : res)
    );
  }
  getPaymentmodeByType(type): Observable<any> {
    return this.apiService.get('/payment/paymentmode/' + type).pipe(
      map(res => res ? res.body : res)
    );
  }
  sendFileResponse(fileUploadResp): Observable<any> {
    return this.apiService.post('/dataupload/bcdetails', fileUploadResp).pipe(
      map(res => res ? res.body : res)
    );
  }

  updateParticular(particular): Observable<any> {
    return this.apiService.put('/productrequest/reference', particular).pipe(
      map(res => res ? res.body : res)
    );
  }
  requestFileupload(file): Observable<any> {
    return this.apiService.postFile('/upload/1', file).pipe(
      map(res => res)
    );
  }
  getCountById_Promise(officeId, productId) {
    return this.apiService.promiseGet('/businesscase/productconsumable/' + officeId + '/' + productId).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getOptionCountById_Promise(officeId, productId, setId) {
    return this.apiService.promiseGet('/businesscase/optionconsumable/' + officeId + '/' + productId + '/' + setId).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getAccounts() {
    return this.apiService.promiseGet('/account').then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getFunds(accountId): Observable<any> {
    return this.apiService.get('/businesscase/fund/' + accountId).pipe(
      map(res => res ? res.body : res)
    );
  }
  submitRating(item): Observable<any> {
    return this.apiService.postT('/rating', item).pipe(
      map(res => res)
    );
  }
  // getUserRoleById(userId): Observable<any> {
  //   return this.apiService.get('/user/rolebyuserid/' + userId).pipe(
  //     map(res => res ? res.body : res)
  //   );
  // }
  getUserRoleById_promise(userId) {
    return this.apiService.promiseGet('/user/rolebyuserid/' + userId).then((res: any) => {
      // if (res.body !== undefined) {
      //   return res.body;
      // } else {
      //   return res;
      // }
      return res.body !== undefined ? res.body : res;

    });
  }
  getProductsById_Promise(Id) {
    return this.apiService.promiseGet('/product/details/' + Id).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
}
