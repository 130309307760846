import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemPendingStatus } from '../dataModel/itemPendingStatus';
import { PendingService } from '../approval/service/pending.service';
import { Rating } from '../dataModel/rating';
import { ContextService } from '../shared/context.service';

@Component({
  selector: 'app-direct-approval',
  templateUrl: './direct-approval.component.html',
  styleUrls: ['./direct-approval.component.css']
})
export class DirectApprovalComponent implements OnInit {
  isSpinner = false;
  status: boolean;
  showingMessage: string;
  linkType: string;

  constructor(
    private readonly route: ActivatedRoute, public pendingService: PendingService,
    public router: Router, public contextService: ContextService) {
  }

  ngOnInit() {

    this.isSpinner = true;
    this.showingMessage = '';

    this.route.queryParams.subscribe(params => {
      console.log('Params', params);

      if (params['data'] !== undefined && params['data'] !== null) {
        console.log('Data3', JSON.parse(window.atob(params['data'])));
        const data: any = JSON.parse(window.atob(params['data']));
        if (data !== undefined && data.type !== undefined && data.content !== undefined) {
          this.linkType = data.type;
          if (data.type === 'approval') {

            this.getPendingTask(data.content);

          } else if (data.type === 'rating') {
            if (params['rating'] !== undefined && params['rating'] !== null) {
              const ratingValue: number = parseInt(params['rating']);
              this.submitRating(data.content, ratingValue);
            } else {
               this.isSpinner = false;
            }
          } else {
           this.isSpinner = false;
          }
        } else {
          this.isSpinner = false;
        }
      } else {
        this.isSpinner = false;
      }
    });
  }

  submitApproval(item, taskId) {

    try {

      this.isSpinner = true;
      const approveProduct: ItemPendingStatus = new ItemPendingStatus();
      approveProduct.taskId = taskId;
      approveProduct.taskType = item.taskType.toLowerCase();;
      approveProduct.content = item.content;

      console.log('Task :', approveProduct);
      this.pendingService.updateTask(approveProduct, item.performerId, taskId).subscribe((data: any) => {
        if (data !== undefined) {
          if (data.code === 200 && data.message === 'Completed') {
            this.status = true;
            this.showingMessage = 'Task Successfully Completed';
          } else {
            this.status = false;
            this.showingMessage = data.message;
          }
        } else {
          this.status = false;
          this.showingMessage = 'Sorry... Please contact to admin';
        }
        this.isSpinner = false;
      },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e);
    }
  }
  submitRating(item: Rating, rateValue: number) {

    try {

      this.isSpinner = true;
      const ratingObj: Rating = new Rating();
      ratingObj.customerId = item.customerId;
      ratingObj.productItemRequestId = item.productItemRequestId;
      ratingObj.rating = rateValue;

      this.pendingService.submitRating(ratingObj).subscribe((data: any) => {

        if (data !== undefined) {
          if (data.code == 200) {
            this.status = true;
            this.showingMessage = 'Thanks For Rating';
            // console.log('Thanks for Rating');
          } else {
            this.showingMessage = data.message;
          }
        } else {
          this.status = false;
          if (this.contextService.errorMessageFromApiService !== undefined &&
            this.contextService.errorMessageFromApiService !== '') {
            this.showingMessage = this.contextService.errorMessageFromApiService;
            this.contextService.errorMessageFromApiService = '';
          }
        }
        this.isSpinner = false;
      },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      this.isSpinner = false;
      console.log(e);
    }
  }
  getPendingTask(task) {

    this.isSpinner = true;
    this.pendingService.getAllTask(task.performerId).subscribe((data: any) => {
      try {
        if (data !== undefined) {
          if (data.data !== undefined) {
            let taskStatus = false;
            for (const pendingTaskRow of data.data) {
              if (task.requestID === pendingTaskRow.content.requestID && task.requestedItemID === pendingTaskRow.content.requestedItemID) {

                this.submitApproval(task, pendingTaskRow.index);

                taskStatus = true;
                break;
              }
            }
            if (!taskStatus) {
              this.showingMessage = 'Task has been already completed.';
              this.isSpinner = false;
            }
          } else {
            this.showingMessage = 'Task has been already completed.';
            this.isSpinner = false;
          }
        } else {
          this.showingMessage = 'Sorry... Please contact to admin';
          this.isSpinner = false;
        }
      } catch (e) {
        this.isSpinner = false;
        console.log('Error:', e.message);
      }
    },
      error => console.log('Error', error)
    );
  }
  backToLogin() {
    if (this.linkType === 'approval') {
      this.router.navigate(['/approval/pendingtasklist']);
    }
  }
}
