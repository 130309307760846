import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperView } from '../shared/helper.view';
import { MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';
import { ContextService } from '../shared/context.service';
import { User } from '../dataModel/user';
import { UsrRole } from '../admin/model/usrRole';
import { RoleFunctionalities } from '../dataModel/enum/roleFunctionalities';
import { UserNodeUser } from '../dataModel/usrNodeUser';
import { ChangePassword } from '../dataModel/change-password';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {

  username: string;
  password: string;
  confirmPassword: string;
  securityCode: string;
  show: boolean = false; // toggle password visiblility
  isSpinner: boolean;
  menuList: any[] = [];
  isForgotPassword: boolean = false;
  isForgotPasswordCalled: boolean = false;
  isTempPassword: boolean = false;
  tempCurrentPassword: string = '';
  isDirectApproval: boolean = false;
  directData: any;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private contextService: ContextService,
    private loginService: LoginService,
    private readonly route: ActivatedRoute) {

  }
  ngOnInit() {

    localStorage.clear();
    sessionStorage.clear();
    this.isDirectApproval = false;
    this.directData = null;
    this.route.queryParams.subscribe(params => {
      if (params['data'] !== undefined) {
        const queryData: any = JSON.parse(window.atob(params['data']));
        console.log(queryData);
        if (queryData !== undefined && queryData.content !== undefined && queryData.content.content === 'Reject') {
          sessionStorage.setItem('reqId_filter', queryData.content.requestID);
          sessionStorage.setItem('itemId_filter', queryData.content.requestedItemID);
          sessionStorage.setItem('performerId_filter', queryData.content.performerId);
        } else {
          this.isDirectApproval = true;
          this.directData = params;
        }
      }
    });
  }
  /**
   * Toggles password visiblility while clicking on icon
   */
  togglePasswordVisiblility(): void {
    this.show = !this.show;
  }
  /**
   * Validates app login component
   * @returns true if validate
   */
  validate(): boolean {

    if (!HelperView.checkStringEmptyOrNull(this.username)) {
      setTimeout(() => document.getElementById('username').focus());
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter username to proceed' });
      return false;
    } else if (!HelperView.checkStringEmptyOrNull(this.password)) {
      setTimeout(() => document.getElementById('password').focus());
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter password to proceed' });
      return false;
    }
    return true;

  }
  onKeydown(event) {
    if (event.key === 'Enter') {
      if (!this.isForgotPassword && !this.isForgotPasswordCalled && !this.isTempPassword) {
        this.signIn(event);
      } else if (this.isForgotPassword && !this.isForgotPasswordCalled) {
        this.sendPassword(event);
      } else if (this.isForgotPasswordCalled || this.isTempPassword) {
        this.changePassword(event);
      }
    }
  }
  fillMenu() {
    this.menuList = [
      {
        label: 'Admin', icon: 'fa fa-cog', isEnabled: false, moduleCode: '',
        items: [
          {
            label: 'Users', icon: 'fa fa-users', routerLink: ['/admin/users-list'],
            isEnabled: false, funId: RoleFunctionalities.ListUser
          },
          {
            label: 'Approval Flows', icon: 'fa fa-sitemap', routerLink: ['/admin/workflowlist'],
            isEnabled: false, funId: RoleFunctionalities.ListWorkFlow
          },
          {
            label: 'Configurations', icon: 'fa fa-puzzle-piece', routerLink: ['/configuration/configurations'],
            isEnabled: false, funId: 101
          },
          {
            label: 'Reminder Mail', icon: 'fa fa-puzzle-piece', routerLink: ['/admin/reminder-mail'],
            isEnabled: false, funId: RoleFunctionalities.ReminderEmail
          },
          // { label: 'My Activity', icon: 'fa fa-list-alt', routerLink: ['/admin/my-activity'], isEnabled: true, funId: null },
          {
            label: 'BC List', icon: 'fa fa-list-alt', routerLink: ['/admin/bc-list'],
            isEnabled: false, funId: RoleFunctionalities.BcList
          },
          {
            label: 'All Request', icon: 'fa fa-file-text-o', routerLink: ['/admin/all-request'],
            isEnabled: false, funId: RoleFunctionalities.ListAllRequest
          },
          {
            label: 'All Activity', icon: 'fa fa-hourglass', routerLink: ['/admin/all-activity'],
            isEnabled: false, funId: RoleFunctionalities.ListAllActivities
          }
        ]
      },
      {
        label: 'Data Upload', icon: 'fa fa-upload', isEnabled: false, moduleCode: 'DUP',
        items: [
          {
            label: 'Office Pool', icon: 'fa fa-file-archive-o', routerLink: ['/accounts/officePool'],
            isEnabled: false, funId: RoleFunctionalities.UploadOfficepool
          },
          {
            label: 'Offline Account', icon: 'fa fa-weibo', routerLink: ['/accounts/offlineAccount'],
            isEnabled: false, funId: RoleFunctionalities.UploadAccountCRM
          },
          {
            label: 'Customer Contact', icon: 'fa fa-file-archive-o', routerLink: ['/accounts/customerContactDetails'],
            isEnabled: false, funId: RoleFunctionalities.UploadCustomerContact
          }
        ]
      },
      {
        label: 'Accounts', icon: 'fa fa-lock', isEnabled: false, moduleCode: 'T03',
        items: [
          {
            label: 'Account', icon: 'fa fa-address-card', routerLink: ['/accounts/account'],
            isEnabled: false, funId: RoleFunctionalities.ListAccount
          },
          {
            label: 'Implemented Products', icon: 'fa fa-cube', routerLink: ['/accounts/implementedProductList'],
            isEnabled: false, funId: RoleFunctionalities.ViewImplementedProduct
          },
          {
            label: 'Account Splitting', icon: 'fa fa-code-fork', routerLink: ['/accounts/swapOffice'],
            isEnabled: false, funId: RoleFunctionalities.AccountSplitting
          }
        ]
      },
      {
        label: 'Bills', icon: 'fa fa-gg-circle', isEnabled: false, moduleCode: 'M01',
        items: [
          {
            label: 'Billing Report', icon: 'fa fa-credit-card', routerLink: ['/billing/billing'],
            isEnabled: false, funId: RoleFunctionalities.ListBill
          },
          {
            label: 'Payment Report', icon: 'fa fa-pie-chart', routerLink: ['/billing/payment-report'],
            isEnabled: false, funId: RoleFunctionalities.PaymentReportConsolidated
          },
          {
            label: 'Payment Details', icon: 'fa fa-file-powerpoint-o', routerLink: ['/billing/payment-report-details'],
            isEnabled: false, funId: RoleFunctionalities.PaymentReportDetailed
          },
          {
            label: 'Billing Update', icon: 'fa fa-line-chart', routerLink: ['/billing/change-billing-request'],
            isEnabled: false, funId: RoleFunctionalities.BillingUpdate
          }
        ]
      },
      {
        label: 'Products', icon: 'fa fa-product-hunt', isEnabled: false, moduleCode: '',
        items: [
          {
            label: 'Products', icon: 'fa fa-podcast', routerLink: ['/products/manage-product'],
            isEnabled: false, funId: RoleFunctionalities.ListProduct
          },
          {
            label: 'New Tag', icon: 'fa fa-tag', routerLink: ['/products/addtag'],
            isEnabled: false, funId: RoleFunctionalities.ListTag
          },
          {
            label: 'Tag Products', icon: 'fa fa-tags  ', routerLink: ['/products/tag-products'],
            isEnabled: false, funId: RoleFunctionalities.TagProduct
          }
        ]
      },
      {
        label: 'Requests', icon: 'fa fa-reply', isEnabled: false, moduleCode: '',
        items: [
          {
            label: 'Request', icon: 'fa fa-wpforms', routerLink: ['/requests/product-request'],
            isEnabled: false, funId: 104          // RoleFunctionalities.ProductRequest
          },
          {
            label: 'My Requests', icon: 'fa fa-indent', routerLink: ['/requests/my-request'],
            isEnabled: false, funId: 103
          },
          {
            label: 'My Bc Uploads', icon: 'fa fa-building', routerLink: ['/requests/my-bc-uploads'],
            isEnabled: false, funId: 102
          },
          {
            label: 'My Activity', icon: 'fa fa-hourglass-start', routerLink: ['/requests/my-activity'],
            isEnabled: true, funId: null
          }
        ]
      },
      {
        label: 'Non Commercial', icon: 'fa fa-ils', isEnabled: false, moduleCode: '',
        items: [
          {
            label: 'Takeback', icon: 'fa fa-retweet', routerLink: ['/requests/takeback'],
            isEnabled: false, funId: RoleFunctionalities.TakeBack
          },
          {
            label: 'Correction', icon: 'fa fa-eraser', routerLink: ['/requests/correction'],
            isEnabled: false, funId: RoleFunctionalities.Correction
          }
        ]
      },
      {
        label: 'Approval', icon: 'fa fa-thumbs-up', isEnabled: true, moduleCode: '',
        items: [
          {
            label: 'Pending Task', icon: 'fa fa-tasks', routerLink: ['/approval/pendingtasklist'],
            isEnabled: true, funId: null
          }
        ]
      }
    ];
  }
  changeEnabledStatus(funId) {
    for (const moduleRow of this.menuList) {
      if (moduleRow.items !== undefined && moduleRow.items.length > 0) {
        for (const funRow of moduleRow.items) {
          if (funRow.funId === funId) {
            funRow.isEnabled = true;
            moduleRow.isEnabled = true;
            return;
          }
        }
      }
    }
  }
  signIn(event) {
    try {
      this.messageService.clear();
      if (this.validate()) {
        sessionStorage.setItem('userAuth', btoa(this.username + ':' + this.password));
        this.isSpinner = true;
        this.loginService.userLogin().subscribe((userData: any) => {
          sessionStorage.removeItem('userAuth');
          try {
            if (userData !== undefined) {
              localStorage.setItem('loginVariable', userData); 
              this.fillMenu();
              if (userData.code === 200 && userData.data !== undefined) {

                let user: User = new User();
                user = userData.data;
                if (user !== undefined) {

                  // localStorage.setItem('userPageFunctionalities', (JSON.stringify(nodeUser.usrNodeUserRole)));
                  // localStorage.setItem('userAuth', btoa(this.username + ':' + this.password));

                  if (user.cntPerson !== undefined && user.cntPerson.cntContact !== undefined) {
                    localStorage.setItem('userMail', user.cntPerson.cntContact[0].email[0].emailId);
                  }

                  this.contextService.isUserLogged = true;
                  localStorage.setItem('userId', user.id.toString());
                  localStorage.setItem('currentUser', user.cntPerson.firstname);
                  localStorage.setItem('userName', user.name);

                  const tempUserRoleList = [];
                  const roleFunctionalities = [];

                  if (user.usrNodeUser !== undefined) {
                    const nodeUser: UserNodeUser = user.usrNodeUser[0];
                    localStorage.setItem('nodeId', nodeUser.node.id.toString());
                    if (nodeUser.usrNodeUserRole !== undefined && nodeUser.usrNodeUserRole.length > 0) {
                      nodeUser.usrNodeUserRole.forEach(userRole => {
                        if (userRole.enabled) {

                          // set role functionalities
                          roleFunctionalities.push(userRole);

                          // for user role list
                          const userRoleRow: UsrRole = { id: userRole.nodeRole.usrRole.id, name: userRole.nodeRole.usrRole.name };
                          tempUserRoleList.push(userRoleRow);

                          // for enable menu settings
                          if (userRole.funNodeFunctionalityRoleUserList !== undefined) {
                            userRole.funNodeFunctionalityRoleUserList.forEach(funRow => {
                              for (const settingsRow of this.contextService.menuListSettings) {
                                if (settingsRow.id === funRow.functionalityId) {
                                  this.changeEnabledStatus(funRow.functionalityId);
                                  break;
                                }
                              }
                              // for enabling takeback and correction page

                              // if (funRow.functionalityId === RoleFunctionalities.TakeBack ||
                              //   funRow.functionalityId === RoleFunctionalities.Correction) {
                              //   this.changeEnabledStatus(100);
                              // }
                              // if (funRow.functionalityId === RoleFunctionalities.Correction) {
                              //   this.changeEnabledStatus(100);
                              // }

                              // for Request page and My Request page
                              if (funRow.functionalityId === RoleFunctionalities.BCRequest ||
                                funRow.functionalityId === RoleFunctionalities.ProductRequest ||
                                funRow.functionalityId === RoleFunctionalities.ITRequest ||
                                funRow.functionalityId === RoleFunctionalities.OfficeRequest) {
                                this.changeEnabledStatus(103);
                                this.changeEnabledStatus(104);
                              }
                              // for My BC uploads page
                              if (funRow.functionalityId === RoleFunctionalities.BCRequest ||
                                funRow.functionalityId === RoleFunctionalities.OfflineBCRequest ||
                                funRow.functionalityId === RoleFunctionalities.AccountManager) {
                                this.changeEnabledStatus(102);
                              }


                              // for enabling configuration page
                              if (funRow.functionalityId === RoleFunctionalities.NeedTrainingManagement ||
                                funRow.functionalityId === RoleFunctionalities.BillingFrequencyManagement ||
                                funRow.functionalityId === RoleFunctionalities.EmailTemplateManagement ||
                                funRow.functionalityId === RoleFunctionalities.UnitManagement ||
                                funRow.functionalityId === RoleFunctionalities.NotificationManagement) {
                                this.changeEnabledStatus(101);
                              }
                            });
                          }
                        }
                      });
                    }

                    if (nodeUser.userRoleDelegationList !== undefined && nodeUser.userRoleDelegationList.length > 0) {
                      const userDelegatedRoles: UsrRole[] = [];
                      for (const delegationRole of nodeUser.userRoleDelegationList) {
                        let status = false;
                        for (const role of tempUserRoleList) {
                          if (role.id === delegationRole.delegatedNodeUserRole.nodeRole.usrRole.id) {
                            status = true;
                            break;
                          }
                        }
                        if (!status) {
                          userDelegatedRoles.push(delegationRole.delegatedNodeUserRole.nodeRole.usrRole);
                        }
                      }
                      localStorage.setItem('userDelegationRoles', JSON.stringify(userDelegatedRoles));
                    }
                  }

                  localStorage.setItem('userPageFunctionalities', (JSON.stringify(roleFunctionalities)));
                  localStorage.setItem('userRoleList', JSON.stringify(tempUserRoleList));
                  // localStorage.setItem('menuList', JSON.stringify(this.menuList));
                  sessionStorage.setItem('menuList', JSON.stringify(this.menuList));
                  this.isSpinner = false;

                  if (!this.isDirectApproval) {
                    this.router.navigate(['/approval/pendingtasklist']);
                  } else {
                    this.router.navigate(['/directapproval'], { queryParams: this.directData });
                  }
                }
              } else if (userData.code === 201) {
                this.isTempPassword = true;
                this.tempCurrentPassword = this.password;
                this.password = '';
                this.messageService.add({ severity: 'success', summary: 'Success', detail: userData.message });
              } else {
                this.username = '';
                this.password = '';
                localStorage.clear();
                setTimeout(() => document.getElementById('username').focus());
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Username or Password' });
              }
            }
            this.isSpinner = false;
          } catch (e) {
            console.log('Error:', e.message);
          }
        },
          error => console.log('Error', error)
        );
      }

    } catch (e) {
      console.log('Error:', e.message);
    }
  }
  sendPassword(event) {

    if (this.username !== '') {
      this.isSpinner = true;
      this.loginService.sendForgotPasswordMail(this.username).subscribe((data: any) => {
        if (data !== undefined) {
          if (data.code === 200) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: data.message });
            this.isForgotPasswordCalled = true;
            this.isForgotPassword = false;
          } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: data.message });
            this.username = '';
            this.isForgotPasswordCalled = false;
            this.isForgotPassword = true;
          }
        }
        this.isSpinner = false;
      },
        error => console.log('Error', error)
      );
    } else {
      setTimeout(() => document.getElementById('username').focus());
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter username to proceed' });
    }
  }
  forgotPassword() {
    this.isForgotPassword = !this.isForgotPassword;
    this.username = '';
    this.password = '';
    this.isForgotPasswordCalled = false;
  }
  changePassword(event) {
    if (this.passwordValidation()) {
      this.isSpinner = true;
      const updateCredential: ChangePassword = new ChangePassword();
      updateCredential.newPassword = this.password;
      updateCredential.userName = this.username;

      if (this.isTempPassword) {
        updateCredential.password = this.tempCurrentPassword;

        this.loginService.changeTempPassword(updateCredential).subscribe((data: any) => {
          if (data !== undefined) {
            if (data.code === 200) {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: data.message });
              this.username = '';
              this.password = '';
              this.confirmPassword = '';
              this.isTempPassword = false;
              this.show = false;
            } else {
              this.messageService.add({ severity: 'warn', summary: 'Warning', detail: data.message });
            }
          }
          this.isSpinner = false;
        },
          error => console.log('Error', error)
        );
      }

      if (this.isForgotPasswordCalled) {
        updateCredential.verificationCode = this.securityCode;

        this.loginService.resetPassword(updateCredential).subscribe((data: any) => {
          if (data !== undefined) {
            if (data.code === 200) {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: data.message });
              this.username = '';
              this.password = '';
              this.confirmPassword = '';
              this.isTempPassword = false;
              this.securityCode = '';
              this.isForgotPasswordCalled = false;
              this.show = false;
            } else {
              this.messageService.add({ severity: 'warn', summary: 'Warning', detail: data.message });
            }
          }
          this.isSpinner = false;
        },
          error => console.log('Error', error)
        );
      }
    }
  }
  checkvalidation() {
    if (this.password !== null) {

      if (this.password.length < 8) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password must contain at least eight characters!' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }

      if (this.password.length > 18) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Only allow to set 18 Characters' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }

      let re = /[0-9]/;
      if (!re.test(this.password)) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password must contain at least One Numeric' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }

      re = /[!@#$%^&*]/;
      if (!re.test(this.password)) {
        // tslint:disable-next-line: max-line-length
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password must contain at least One Special Character' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }

      re = /[a-z]/;
      if (!re.test(this.password)) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password must contain at least One lower case' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }
      re = /[A-Z]/;
      if (!re.test(this.password)) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password must contain at least One Upper case' });
        setTimeout(() => document.getElementById('password').focus());
        return false;
      }

    } else {
      this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Please enter Password' });
      setTimeout(() => document.getElementById('password').focus());
      return false;
    }
  }
  passwordValidation(): boolean {
    if (this.isForgotPasswordCalled) {
      if (this.securityCode == null) {
        this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Please Set Code' });
        setTimeout(() => document.getElementById('securityId').focus());
        this.isSpinner = false;
        return false;
      }
    }
    if (this.password === null) {
      this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Please Set  Password' });
      setTimeout(() => document.getElementById('password').focus());
      this.isSpinner = false;
      return false;
    }
    if (this.confirmPassword === null) {
      this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Please Set Confirm Password' });
      setTimeout(() => document.getElementById('confirmPassword').focus());
      this.isSpinner = false;
      return false;
    }
    if (this.password !== null && this.password !== this.confirmPassword) {
      this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Password Missmatch' });
      setTimeout(() => document.getElementById('password').focus());
      return false;
    }
    return true;
  }

  checkStatus() {
    if (this.password !== undefined && this.password.length > 54) {
      this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'Limit Exceed!' });
      setTimeout(() => document.getElementById('password').focus());
      return false;
    }
  }
}
