import { ViewProduct } from './view-product';
import { ViewOption } from './view-option';

export class BundleSet {
    id: number;
    name: string;
    totalMin: number;
    totalMax: number;
    product: ViewProduct[];
    isDetailsShow: boolean;
    isBundle: boolean;
    isSet: boolean;
    childCount: number;
    optionProductStatus: boolean;
}
