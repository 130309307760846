import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Workflow } from 'src/app/dataModel/work-flow';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  workFlow: Workflow;
  validStatus: boolean;
  validResponse: string;
  userId: number;
  userData: any;

  constructor(private apiService: ApiService) { }


  getAllHeirarchy(): Observable<any> {
    return this.apiService.get('/heirarchy/all').pipe(
      map(res => res ? res.body : res)
    );
  }
  updateStatus(dataObj): Observable<any> {
    return this.apiService.post('/request/tasks/' + localStorage.getItem('userRole'), dataObj).pipe(
      map(res => res ? res.body : res)
    );
  }
  addProduct(product): Observable<any> {
    return this.apiService.post('/product/insert', product).pipe(
      map(res => res ? res.body : res)
    );
  }
  // updateProduct(product, userRole, taskIndex): Observable<any> {
  //   return this.apiService.put("/request/" + userRole + "/" + taskIndex, product).pipe(
  //     map(res => res ? res.body : res )
  //   );
  // }
  getProcess(): Observable<any> {
    return this.apiService.get('/request/processes').pipe(
      map(res => res ? res.body : res)
    );
  }
  getProcessById(id): Observable<any> {
    return this.apiService.get('/request/processes/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getProcessHistoryById(id): Observable<any> {
    return this.apiService.get('/request/processHistory/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getCategoryNames(): Observable<any> {
    return this.apiService.get('/heirarchy/categoryNames').pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllTask(roleId): Observable<any> {
    return this.apiService.get('/request/tasks/' + roleId).pipe(
      map(res => res ? res.body : res)
    );
  }
  addTags(product): Observable<any> {
    return this.apiService.post('/heirarchy/all', product).pipe(
      map(res => res ? res.body : res)
    );
  }
  getWorkFlowList(): Observable<any> {
    return this.apiService.get('/workflow').pipe(
      map(res => res ? res.body : res)
    );
  }
  addWorkFlow(workflow): Observable<any> {
    return this.apiService.post('/workflow', workflow).pipe(
      map(res => res ? res.body : res)
    );
  }
  deleteWorkFlow(workflowId): Observable<any> {
    return this.apiService.delete('/workflow/' + workflowId).pipe(
      map(res => res ? res.body : res)
    );
  }
  updateWorkFlow(workflow): Observable<any> {
    return this.apiService.put('/workflow', workflow).pipe(
      map(res => res ? res.body : res)
    );
  }
  getUnits(): Observable<any> {
    return this.apiService.get('/unit').pipe(
      map(res => res ? res.body : res)
    );
  }
  getPeriods(): Observable<any> {
    return this.apiService.get('/period').pipe(
      map(res => res ? res.body : res)
    );
  }
  getPlans(): Observable<any> {
    return this.apiService.get('/plan').pipe(
      map(res => res ? res.body : res)
    );
  }
  getProducts(): Observable<any> {
    return this.apiService.get('/product/details').pipe(
      map(res => res ? res.body : res)
    );
  }
  addBundle(product): Observable<any> {
    return this.apiService.post('/product/bundle', product).pipe(
      map(res => res ? res.body : res)
    );
  }
  getProductById(id): Observable<any> {
    return this.apiService.get('/product/details/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getMarket(): Observable<any> {
    return this.apiService.get('/region').pipe(
      map(res => res ? res.body : res)
    );
  }
  getTag(): Observable<any> {
    return this.apiService.get('/tag').pipe(
      map(res => res ? res.body : res)
    );
  }
  getRole(): Observable<any> {
    return this.apiService.get('/workflow/rolelist').pipe(
      map(res => res ? res.body : res)
    );
  }
  getUser(): Observable<any> {
    return this.apiService.get('/workflow/userlist').pipe(
      map(res => res ? res.body : res)
    );
  }
  getWorkFlowById(id): Observable<any> {
    return this.apiService.get('/workflow/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getpaymentMode(categoryId): Observable<any> {
    return this.apiService.get('/payment/paymentmode/' + categoryId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getpaymentModeForWorkflow(): Observable<any> {
    return this.apiService.get('/category/paymentmode').pipe(
      map(res => res ? res.body : res)
    );
  }
  getCategory(): Observable<any> {
    return this.apiService.get('/category/itemcategory').pipe(
      map(res => res ? res.body : res)
    );
  }
  getcontractPeriod(): Observable<any> {
    return this.apiService.get('/contract').pipe(
      map(res => res ? res.body : res)
    );
  }
  getMarker(): Observable<any> {
    return this.apiService.get('/marker').pipe(
      map(res => res ? res.body : res)
    );
  }
  getCity(country): Observable<any> {
    return this.apiService.get('/country/' + country).pipe(
      map(res => res ? res.body : res)
    );
  }
  getCountry(): Observable<any> {
    return this.apiService.get('/country').pipe(
      map(res => res ? res.body : res)
    );
  }
  updateProduct(product): Observable<any> {
    return this.apiService.put('/product', product).pipe(
      map(res => res ? res.body : res)
    );
  }
  addUser(details, nodeId): Observable<any> {
    return this.apiService.post('/person/user/' + nodeId, details).pipe(
      map(res => res ? res.body : res)
    );
  }
  updateUser(details, nodeId): Observable<any> {
    return this.apiService.put('/person/userupdate/' + nodeId, details).pipe(
      map(res => res ? res.body : res)
    );
  }
  getUserDetailsByID(userId): Observable<any> {
    return this.apiService.get('/person/userdetails/' + userId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getRoleFunctionality(id): Observable<any> {
    return this.apiService.get('/node/rolefunctionality/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllUsers(id): Observable<any> {
    return this.apiService.get('/node/userrolefunctionality/' + id).pipe(
      map(res => res ? res.body : res)
    );
  }
  getTittle(): Observable<any> {
    return this.apiService.get('/person/titlelist').pipe(
      map(res => res ? res.body : res)
    );
  }
  getProductIdStatus(loginId): Observable<any> {
    return this.apiService.get('/person/isexistloginid/' + loginId).pipe(
      map(res => res ? res.body : res)
    );
  }
  updateBundle(product): Observable<any> {
    return this.apiService.put('/product/bundle', product).pipe(
      map(res => res ? res.body : res)
    );
  }
  sendMail(userId): Observable<any> {
    return this.apiService.post('/email/' + userId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getProductCodeStatus(code) {
    return this.apiService.promiseGet('/product/productcodeexist/' + code).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getOptionCodeStatus(code) {
    return this.apiService.promiseGet('/product/optioncodeexist/' + code).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  requestFileupload(file, folderPath): Observable<any> {
    return this.apiService.postFile('/upload/' + folderPath, file).pipe(
      map(res => res)
    );
  }
  getLevel(): Observable<any> {
    return this.apiService.get('/businesscase/approvallevellist').pipe(
      map(res => res ? res.body : res)
    );
  }
  getAcoLevel(): Observable<any> {
    return this.apiService.get('/businesscase/acoapprovallist').pipe(
      map(res => res ? res.body : res)
    );
  }
  getdocumentType(): Observable<any> {
    return this.apiService.get('/document').pipe(
      map(res => res ? res.body : res)
    );
  }
  getRoleFunctionality_Promise(id) {
    return this.apiService.promiseGet('/node/rolefunctionality/' + id).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getWorkFlowList_Promise() {
    return this.apiService.promiseGet('/workflow').then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getAllBCList(accountId): Observable<any> {
    return this.apiService.get('/productrequest/getallbcrequest/' + accountId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllStatus(): Observable<any> {
    return this.apiService.get('/productrequest/requeststatus').pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllOfflineList(accid): Observable<any> {
    return this.apiService.get('/businesscase/offlinebclist/' + accid).pipe(
      map(res => res ? res.body : res)
    );
  }
  getDesignation(): Observable<any> {
    return this.apiService.get('/designation').pipe(
      map(res => res ? res.body : res)
    );
  }
  getbillingFrequency(): Observable<any> {
    return this.apiService.get('/billingfrequency').pipe(
      map(res => res ? res.body : res)
    );
  }
  insertDelegation(details): Observable<any> {
    return this.apiService.post('/delegation', details).pipe(
      map(res => res ? res.body : res)
    );
  }
  updateDelegation1(details): Observable<any> {
    return this.apiService.put('/delegation', details).pipe(
      map(res => res ? res.body : res)
    );
  }
  getDelegation(userid): Observable<any> {
    return this.apiService.get('/delegation/' + userid).pipe(
      map(res => res ? res.body : res)
    );
  }
  // getApprovalUser(): Observable<any> {
  //   return this.apiService.get('/person/approvalfunctionalityuserlist').pipe(
  //     map(res => res ? res.body : res )
  //   );
  // }
  getWorkFlowType(): Observable<any> {
    return this.apiService.get('/workflow/approvalflowtypelist').pipe(
      map(res => res ? res.body : res)
    );
  }
  /**
   * @function  sendReminder
   * @description  to send reminder for first pending user
   * @param reqId,prodId (Request Id & Product Id)
   * @returns success message after send mail
   * 
   */
  sendReminder(reqId,prodId):Observable<any>{
    return this.apiService.get('/request/sendReminder/'+reqId+'/'+prodId,).pipe(
      map(res => res ? res.body : res)
    );
  }
   /**
   * @function getReminderType
   * @description  to get remindertypes list
   * @returns remindertypeslist
   * 
   */
  getReminderType(): Observable<any> {
    return this.apiService.get('/reminder/remindertypelist').pipe(
      map(res => res ? res.body : res)
    );
  }
  /**
   * @function  addReminderType
   * @description  to create new remindertype with email intervals
   * @param details (remindertype and interval)
   * @returns  message after creating new reminder type
   * 
   */
 
  addReminderType(details): Observable<any> {
    return this.apiService.post('/reminder',details).pipe(
      map(res => res ? res.body : res)
    );
  }
   /**
   * @function  editReminderType
   * @description  to edit existing remindertype with new email intervals
   * @param details (remindertype and interval)
   * @returns message after updating new email intervals
   * 
   */
  editReminderType(details): Observable<any> {
    return this.apiService.put('/reminder',details).pipe(
      map(res => res ? res.body : res)
    );
  }
  /**
   * @function   getReminderList
   * @description  to get already created remindertypes with email intervals
   * @returns reminderlists with email intervals
   * 
   */
  getReminderList(): Observable<any> {
    return this.apiService.get('/reminder').pipe(
      map(res => res ? res.body : res)
    );
  }
    /**
   * @function   deleteReminder
   * @description  to delete existing reminder type with interval
   * @param details reminder type's id
   * @returns message after deleting item
   * 
   */
  deleteReminder(deleteId): Observable<any> {
    return this.apiService.delete('/reminder/'+ deleteId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getApprovalUser(): Observable<any> {
    return this.apiService.get('/workflow/userlist').pipe(
      map(res => res ? res.body : res)
    );
  }
  updateDelegationStatus(details): Observable<any> {
    return this.apiService.post('/delegation/updatestatus', details).pipe(
      map(res => res ? res.body : res)
    );
  }
  deleteDelegation(deleteId): Observable<any> {
    return this.apiService.delete('/delegation/' + deleteId).pipe(
      map(res => res ? res.body : res)
    );
  }
  enabledUser(details): Observable<any> {
    return this.apiService.post('/user/enableuser', details).pipe(
      map(res => res ? res.body : res)
    );
  }
  disabledUser(details): Observable<any> {
    return this.apiService.post('/user/disableuser', details).pipe(
      map(res => res ? res.body : res)
    );
  }
  getActivityDetailsLazy(userId, lazyDetails): Observable<any> {
    return this.apiService.post('/productrequest/lazy/requestmyactivities/' + userId, lazyDetails).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllRequestListLazy(lazyDetails): Observable<any> {
    return this.apiService.post('/productrequest/lazy', lazyDetails).pipe(
      map(res => res ? res.body : res)
    );
  }
  getRequestById(reqId): Observable<any> {
    return this.apiService.get('/productrequest/' + reqId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getItemHistoryId(id) {
    return this.apiService.promiseGet('/productrequest/itemhistorylist/' + id).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getAllActivityLazy(accountId, lazyDetails): Observable<any> {
    return this.apiService.post('/productrequest/lazy/allactivity/' + accountId, lazyDetails).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAccounts(): Observable<any> {
    return this.apiService.get('/account').pipe(
      map(res => res ? res.body : res)
    );
  }
  getItemById(reqId, itemId): Observable<any> {
    return this.apiService.get('/productrequest/' + reqId + '/' + itemId).pipe(
      map(res => res ? res.body : res)
    );
  }
  getAllActivity(accountId) {
    return this.apiService.promiseGet('/productrequest/allactivity/' + accountId).then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }
  getAllRequestWithOutLazy() {
    return this.apiService.promiseGet('/productrequest').then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }

  getAllProductRequestWithOutLazy(): any {
    return this.apiService.promiseGet('/productrequest/all').then((res: any) => {
      if (res.body !== undefined) {
        return res.body;
      } else {
        return res;
      }

    });
  }

}
