import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ProductNameType } from '../products/model/productNameType';
import { Product } from '../products/model/products';
import { Subject, Observable } from 'rxjs';
import { ViewOffice } from '../viewModel/view-office';
import { ViewPendingTask } from '../approval/model/viewPendingTask';
import { Router } from '@angular/router';
import { RoleFunctionalities } from '../dataModel/enum/roleFunctionalities';

@Injectable({
  providedIn: 'root',

})
export class ContextService {
  subject = new Subject<any>();
  isUserLogged: boolean = false;
  viewRows: number = 10;
  cartData: ViewOffice[] = [];
  productList: TreeNode[] = [];
  tagList: ProductNameType[] = [];
  productsTagList: Product[] = [];
  reviewData: ViewPendingTask;
  workflowList: any[];
  currency: string = 'EUR';
  userId: number;
  userIdStatus: string;
  validStatus: boolean;
  validResponse: string;
  // rows: number = 10;
  errorMessageFromApiService: string;  // using direct-approval component only

  // for enabling menu list functionalities in login component
  menuListSettings: any = [
    { id: RoleFunctionalities.ListUser, name: 'UsersList' },
    { id: RoleFunctionalities.ListWorkFlow, name: 'Workflow List' },
    { id: RoleFunctionalities.ProductRequest, name: 'Request' },
    { id: RoleFunctionalities.ListTag, name: 'Tag List' },
    { id: RoleFunctionalities.TagProduct, name: 'Tag product' },
    { id: RoleFunctionalities.ListProduct, name: 'Product' },
    { id: RoleFunctionalities.ListAccount, name: 'Account' },
    { id: RoleFunctionalities.ListBill, name: 'Bill List' },
    { id: RoleFunctionalities.OfflineBCRequest, name: 'OfflineBcRequest' },
    { id: RoleFunctionalities.UploadOfficepool, name: 'UploadOfficepool' },
    { id: RoleFunctionalities.UploadAccountCRM, name: 'UploadAccountCRM' },
    { id: RoleFunctionalities.UploadCustomerContact, name: 'UploadCustomerContact' },
    { id: RoleFunctionalities.ReminderEmail, name: 'ReminderEmail' },
    { id: RoleFunctionalities.ViewImplementedProduct, name: 'ViewImplementedProduct' },
    { id: RoleFunctionalities.AccountSplitting, name: 'AccountSplitting' },
    { id: RoleFunctionalities.BcList, name: 'BcList' },

    { id: RoleFunctionalities.TakeBack, name: 'TakeBack' },
    { id: RoleFunctionalities.Correction, name: 'Correction' },
    { id: RoleFunctionalities.BillingUpdate, name: 'BillingUpdate' },

    { id: RoleFunctionalities.PaymentReportConsolidated, name: 'PaymentReportConsolidate' },
    { id: RoleFunctionalities.PaymentReportDetailed, name: 'PaymentReportDetails' },
    { id: RoleFunctionalities.ListAllActivities, name: 'ListAllActivities' },
    { id: RoleFunctionalities.ListAllRequest, name: 'ListAllRequest' },

    // { id: RoleFunctionalities.AccountManager, name: 'AccountManager' },

  ];

  requestReview: any; // for review product request

  accountIdForReviewRequest: number;


  constructor(public router: Router) { }
  getCart(): Observable<any> {
    return this.subject.asObservable();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

}
