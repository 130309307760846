// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,

  fileServer_Api_Url: 'https://uatocfile.amadeus.ae',  // test
  bucketUsernamePassword: 'ONC1:1Qaz2Wsx!',            // test
  api_url: 'https://uatoc.amadeus.ae',                 // test

  // fileServer_Api_Url: 'https://ocapp.amadeus.ae:9433',   // Live
  // bucketUsernamePassword: 'ONC1:1Qaz2Wsx!',              // Live
  // api_url: 'https://ocapp.amadeus.ae:9453',              // Live


  // fileServer_Api_Url: 'https://oneconnectfileupload.oneviewitsolutions.com:9433',   // staging
  // bucketUsernamePassword: 'ONC1:1Qaz2Wsx!',                                         // staging
  // api_url: 'https://ser-oneconnect.oneviewitsolutions.com:9453',                    // staging

  // api_url: 'https://ser-oneconnect.oneviewitsolutions.com:8453',                  // developer
  // api_url: 'https://192.168.1.33:9443',                                          // local

};