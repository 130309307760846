import { Unit } from '../dataModel/unit';
import { Period } from '../dataModel/period';
import { RecurringPeriod } from '../dataModel/recurringPeriod';

export class SlabRate {
    id: number;
    quantityFrom: number;
    quantityTo: number;
    netAmount: number;
    recommendedAmount: number;
    unit: Unit;
    recurringPeriod: RecurringPeriod;

}
