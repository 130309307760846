import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `<div class="layout-topbar padding-set ftr_btm footer-height">
        <div class="layout-footer clearfix">
            <a>
                <img alt="logo-colored" src="assets/layout/images/footer_logo.png" />
            </a>
            <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>All Rights Reserved</span>
            </span>
        </div></div>`
})
export class AppFooterComponent {

}
